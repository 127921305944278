import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../config';
import '../styles/numberModal.css';
import InputMask from 'react-input-mask';

const NumberModal = ({ onClose }) => {
    const [number, setNumber] = useState('');

    const handleNumberChange = (e) => {
        setNumber(e.target.value);
    };

    const handleSubmit = async () => {
        if (number) {
            const user = auth.currentUser;
            if (user) {
                const userDocRef = doc(db, 'users', user.uid);
                await updateDoc(userDocRef, { number });
                onClose();
            }
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content-number">
                <h2>KaspiGold нөміріңізді енгізіңіз</h2>
                <InputMask
                    mask="+7 (999) 999 - 99 - 99"
                    value={number}
                    onChange={handleNumberChange}
                    placeholder="+7 (___) ___ - __ - __"
                    className="number-input"
                />
                <button onClick={handleSubmit} className="submit-button">
                    Сақтау
                </button>
                <p className='number-info-text'>1.Сіздің KaspiGold нөміріңіз алдағы уақытта платформаның ішіндегі ақылы аудио кітаптарды сатып алу үшін қажет.</p>
                <p className='number-info-text'>2.Аудио кітапты сатып алуға ұсыныс жасағаннан кейін сізге төлем нөмірі KaspiGold-қа жіберіледі. Төлем жасағаннан кейін аудио кітап сізге 2 минут ішінде қолжетімді болады.</p>
            </div>
        </div>
    );
};

export default NumberModal;