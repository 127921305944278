import React, { useState, useEffect } from 'react';
import { auth, db } from '../config';
import { onAuthStateChanged, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from "firebase/firestore";
import Modal from './ModalSign.js';
import '../styles/form.css';
import google_icon from '../icons/google_icon.png';
import email_icon from '../icons/email.png';
import estiLogo from '../EstiLogo/Esti3.png';

import gif2 from '../icons/play.gif';
import gif3 from '../icons/Unmute.gif';
import gif4 from '../icons/microphone.gif';

import ios_share from '../icons/ios_share.svg';
import ios_add from '../icons/ios add.svg';

const SignIn = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showInstallPrompt, setShowInstallPrompt] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                navigate('/home');
                localStorage.setItem('displayName', user.displayName);
                localStorage.setItem('photoURL', user.photoURL);
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        if (isIos) {
            setShowInstallPrompt(true);
        }
    }, []);

    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            await setDoc(doc(db, "users", user.uid), {
                uid: user.uid,
                displayName: user.displayName,
                email: user.email,
                photoURL: user.photoURL,
                lastLogin: new Date()
            }, { merge: true });

        } catch (err) {
            console.error("Ошибка при входе через Google:", err);
        }
    };

    const handleEmailAuth = () => {
        setIsModalOpen(true);
    };

    return (
        <div className="signin-container">
            <div class="area" >
                <ul class="circles">
                    {/* <li><img className='size-50' src={gif} alt='test'/></li> */}
                    <li><img className='size-50' src={gif2} alt='test'/></li>
                    <li><img className='size-50' src={gif3} alt='test'/></li>
                    <li><img className='size-50' src={gif4} alt='test'/></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div >
            {showInstallPrompt && (
                <div className="install-prompt">
                    <p>Қосымшаны орнату үшін мына батырманы басыңыз: <img src={ios_share} alt='ios_share' /></p>
                    <p>Басты бетке қосу <img src={ios_add} alt='ios_add' /></p>
                </div>
            )}
            <div className='shadow'></div>
            <div></div>
            <div className='form'>
                <img src={estiLogo} alt='estiLogo' />
                <div className="signin-form">
                    <button onClick={handleGoogleSignIn} className="btn"><img className='google_icon' src={google_icon} alt='google_icon' />Google арқылы кіріңіз</button>
                    <button onClick={handleEmailAuth} className="btn"><img className='email_icon' src={email_icon} alt='email_icon' />Email арқылы кіріңіз</button>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </div>
    );
};

export default SignIn;