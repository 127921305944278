import React, { useState } from 'react';
import '../styles/modaloptions.css';

const ModalOptions = ({ onClose, onDelete, onSaveMessage, savedTime }) => {
    const [message, setMessage] = useState('');

    const handleSaveMessage = () => {
        if (message.trim()) {
            onSaveMessage(message);
            setMessage('');
        }
    };

    return (
        <div className="modal-options">
            <div className="modal-content-options">
                <input
                    type="text"
                    placeholder="Хабарлама"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <button onClick={handleSaveMessage}>Хабарламаны сақтау</button>
                <button onClick={onDelete}>Жою</button>
                <button onClick={onClose}>Жабу</button>
            </div>
        </div>
    );
};

export default ModalOptions;