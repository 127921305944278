import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth, db, storage } from '../config';
import '../styles/home.css';
import homeIcon from '../icons/home.png';
import searchIcon from '../icons/search.png';
import playIcon from '../icons/play.png';
import Read from './Read';
import '../styles/saves.css';
import settingsIcon from '../icons/settings.svg';
import menuIcon from '../icons/menu.svg';

const Saves = () => {
    const [userBooks, setUserBooks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedBook, setSelectedBook] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [photoUrl, setPhotoUrl] = useState('');
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [expandedSection, setExpandedSection] = useState(null); // Track which section is expanded
    const [feedbackText, setFeedbackText] = useState('');
    const [feedbackImage, setFeedbackImage] = useState(null);
    const [helpText, setHelpText] = useState('');
    const userPicture = localStorage.getItem('photoURL');

    useEffect(() => {
        const userDisplayName = localStorage.getItem('displayName');
        const userPhotoUrl = localStorage.getItem('photoURL');
        setDisplayName(userDisplayName);
        setPhotoUrl(userPhotoUrl);

        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                const fetchUserBooks = async () => {
                    try {
                        const userDocRef = doc(db, 'users', user.uid);
                        const userDocSnap = await getDoc(userDocRef);
                        if (userDocSnap.exists()) {
                            const userData = userDocSnap.data();
                            const bookIds = userData.books || [];
                            const fetchedBooks = await Promise.all(
                                bookIds.map(async (bookId) => {
                                    const bookDocRef = doc(db, 'books', bookId);
                                    const bookDocSnap = await getDoc(bookDocRef);
                                    if (bookDocSnap.exists()) {
                                        return { id: bookId, ...bookDocSnap.data() };
                                    } else {
                                        return null;
                                    }
                                })
                            );
                            setUserBooks(fetchedBooks.filter(book => book !== null));
                        }
                    } catch (error) {
                        console.error('Error fetching user books: ', error);
                    } finally {
                        setLoading(false);
                    }
                };

                fetchUserBooks();
            }
        });

        return () => unsubscribe();
    }, []);

    const handleReadButtonClick = (book) => {
        setSelectedBook(book);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleLogout = () => {
        auth.signOut().then(() => {
            localStorage.clear();
        });
    };

    const handleSettingsClick = () => {
        if (!isSettingsOpen) {
            setIsSettingsOpen(true);
            setIsMenuOpen(false); // Close menu if it's open
        } else {
            setIsSettingsOpen(false);
        }
    };

    const handleSaveSettings = async () => {
        try {
            const user = auth.currentUser;
            if (user) {
                await updateDoc(doc(db, 'users', user.uid), {
                    displayName,
                    photoUrl,
                });
                localStorage.setItem('displayName', displayName);
                localStorage.setItem('photoURL', photoUrl);
                setIsSettingsOpen(false);
            }
        } catch (error) {
            console.error('Error updating settings: ', error);
        }
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPhotoUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleMenuClick = (e) => {
        if (!isMenuOpen) {
            setIsMenuOpen(true);
            setIsSettingsOpen(false); // Close settings if it's open
        } else {
            setIsMenuOpen(false);
        }
        e.stopPropagation();
    };

    const handleSectionToggle = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };

    const handleFeedbackChange = (e) => {
        setFeedbackText(e.target.value);
    };

    const handleFeedbackImageChange = (e) => {
        setFeedbackImage(e.target.files[0]);
    };

    const handleHelpChange = (e) => {
        setHelpText(e.target.value);
    };

    const handleFeedbackSubmit = async (e) => {
        e.preventDefault();
        try {
            const user = auth.currentUser;
            if (user) {

                let imageUrl = null;
                if (feedbackImage) {
                    const imageRef = ref(storage, `feedbackImages/${Date.now()}_${feedbackImage.name}`);
                    await uploadBytes(imageRef, feedbackImage);
                    imageUrl = await getDownloadURL(imageRef);
                }

                const feedbackRef = collection(db, 'feedback');
                await addDoc(feedbackRef, {
                    userId: user.uid,
                    text: feedbackText,
                    image: imageUrl,
                });

                setFeedbackText('');
                setFeedbackImage(null);
                handleSectionToggle('feedback');
            }
        } catch (error) {
            console.error('Error submitting feedback: ', error);
        }
    };

    const handleHelpSubmit = async (e) => {
        e.preventDefault();
        try {
            const user = auth.currentUser;
            if (user) {
                const helpRef = collection(db, 'help');
                await addDoc(helpRef, {
                    userId: user.uid,
                    text: helpText,
                });
                setHelpText('');
                handleSectionToggle('help');
            }
        } catch (error) {
            console.error('Error submitting help request: ', error);
        }
    };

    const filteredBooks = userBooks.filter(book =>
        book.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="home-container">
            <header className="header-saves">
                <input
                    type="text"
                    className="search-input"
                    placeholder="Іздеу..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </header>
            <main className="main-content-saves">
                <button className="settings-button" onClick={handleSettingsClick}>
                    <img src={settingsIcon} alt="settings" />
                </button>

                {isSettingsOpen && (
                    <div className={`settings-form ${isSettingsOpen ? 'open' : 'closed'}`}>
                        <h2>Профиль</h2>
                        <label>Атыңыз:</label>
                        <input
                            type="text"
                            value={displayName}
                            onChange={(e) => setDisplayName(e.target.value)}
                        />
                        <label>Фотосурет:</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileUpload}
                        />
                        <button className='settings-save' onClick={handleSaveSettings}>Сақтау</button>
                        <button className='settings-logout' onClick={handleLogout}>Шығу</button>
                    </div>
                )}

                <button className="menu-button" onClick={handleMenuClick}>
                    <img src={menuIcon} alt="menu" />
                </button>

                {isMenuOpen && (
                    <div className="menu">
                        <ul>
                            <li>
                                <a href="#faq" onClick={() => handleSectionToggle('faq')}>
                                    Жиі қойылатын сұрақтар
                                </a>
                                {expandedSection === 'faq' && (
                                    <ul>
                                        <li><p className='number-info-text'>1.Сіздің KaspiGold нөміріңіз алдағы уақытта платформаның ішіндегі ақылы аудио кітаптарды сатып алу үшін қажет.</p></li>
                                        <li><p className='number-info-text'>2.Аудио кітапты сатып алуға ұсыныс жасағаннан кейін сізге төлем нөмірі KaspiGold-қа жіберіледі. Төлем жасағаннан кейін аудио кітап сізге 2 минут ішінде қолжетімді болады.</p></li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <button className="donation-button">Қайырымдылық</button>
                                {expandedSection === 'donation' && (
                                    <button className="donation-button">Donate</button>
                                )}
                            </li>
                            <li>
                                <a href="#feedback" onClick={() => handleSectionToggle('feedback')}>
                                    Кері байланыс
                                </a>
                                {expandedSection === 'feedback' && (
                                    <form className="feedback-form-container" onSubmit={handleFeedbackSubmit}>
                                        <label className="feedback-form-label">Мәтін:</label>
                                        <textarea
                                            className="feedback-form-textarea"
                                            value={feedbackText}
                                            onChange={handleFeedbackChange}
                                        />
                                        <label className="feedback-form-label">Фотосурет:</label>
                                        <input
                                            className="feedback-form-file-input"
                                            type="file"
                                            accept="image/*"
                                            onChange={handleFeedbackImageChange}
                                        />
                                        <button className="feedback-form-submit-button" type="submit">Жіберу</button>
                                    </form>
                                )}
                            </li>
                            <li>
                                <a href="#help" onClick={() => handleSectionToggle('help')}>
                                    Көмек
                                </a>
                                {expandedSection === 'help' && (
                                    <form onSubmit={handleHelpSubmit} className="help-form">
                                        <label className="help-form-label">Мәтін:</label>
                                        <input
                                            type="text"
                                            value={helpText}
                                            onChange={handleHelpChange}
                                            className="help-form-input"
                                        />
                                        <button type="submit" className="help-form-submit-button">Жіберу</button>
                                    </form>
                                )}
                            </li>
                        </ul>
                    </div>
                )}

                <div className="user-info">
                    {photoUrl && <img src={photoUrl} alt="User" className="user-photo" />}
                    <h2 className="user-name">{displayName}</h2>
                </div>
            </main>

            <footer className="footer">
                {loading ? (
                    <div className="spinner"></div>
                ) : filteredBooks.length > 0 ? (
                    <ul className="book-list">
                        {filteredBooks.map((book, index) => (
                            <li key={index} className="book-item">
                                <div className="book-play-icon">
                                    <img src={book.imageUrl} alt={book.title} className="book-image2" />
                                    <button
                                        className="read-button-saves"
                                        onClick={() => handleReadButtonClick(book)}
                                    >
                                        <img className="icon-play-saves" src={playIcon} alt="saves-play" />
                                    </button>
                                </div>
                                <h3 className="book-title">{book.title}</h3>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="noSavedBooks">Сатып алынған кітаптар табылмады.</p>
                )}
                <nav className="footer-nav">
                    <ul className="nav-list">
                        <li className="nav-item">
                            <Link to="/home" className="nav-link">
                                <img src={homeIcon} alt="Home" className="nav-icon" />
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/books" className="nav-link">
                                <img src={searchIcon} alt="Books" className="nav-icon" />
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/saves" className="nav-link">
                                <img style={{ borderRadius: '100%', border: '1px solid #fff' }} src={userPicture} alt="Saved" className="nav-icon" />
                            </Link>
                        </li>
                    </ul>
                </nav>
            </footer>

            {isModalOpen && <Read book={selectedBook} closeModal={closeModal} />}
        </div>
    );
};

export default Saves;