import React, { useState, useEffect, useRef } from 'react';
import '../styles/modal.css';
import AudioControl from './AudioControl';
import ModalOptions from './ModalOptions';

import backIcon from '../icons/back.svg';

const Read = ({ book, closeModal }) => {
    const modalContentRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [currentTab, setCurrentTab] = useState('listen');
    const [savedTimes, setSavedTimes] = useState({});
    const [savedMessages, setSavedMessages] = useState({});
    const [savedMessage, setSavedMessage] = useState('');
    const [showOptionsModal, setShowOptionsModal] = useState(false);
    const [selectedAudioUrl, setSelectedAudioUrl] = useState(null);
    const [playingAudio, setPlayingAudio] = useState(null);

    useEffect(() => {
        const savedScrollPosition = localStorage.getItem(`scrollPosition_${book.title}`);
        if (savedScrollPosition) {
            setScrollPosition(parseInt(savedScrollPosition, 10));
        }

        const savedTimes = localStorage.getItem(`savedTimes_${book.title}`);
        if (savedTimes) {
            setSavedTimes(JSON.parse(savedTimes));
        }

        const savedMessages = localStorage.getItem(`savedMessages_${book.title}`);
        if (savedMessages) {
            setSavedMessages(JSON.parse(savedMessages));
        }
    }, [book.title]);

    useEffect(() => {
        if (modalContentRef.current) {
            modalContentRef.current.scrollTop = scrollPosition;
        }
    }, [scrollPosition]);

    const handleScroll = () => {
        if (modalContentRef.current) {
            localStorage.setItem(`scrollPosition_${book.title}`, modalContentRef.current.scrollTop);
        }
    };

    const saveCurrentTime = (audioUrl, currentTime) => {
        const minutes = Math.floor(currentTime / 60);
        const seconds = Math.floor(currentTime % 60);
        const timeString = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        const newSavedTimes = { ...savedTimes, [audioUrl]: timeString };
        setSavedTimes(newSavedTimes);
        localStorage.setItem(`savedTimes_${book.title}`, JSON.stringify(newSavedTimes));
        setSavedMessage(`Уақыт ${timeString} сақталды`);
        setTimeout(() => setSavedMessage(''), 3000);
    };

    const handleSavedTimeClick = (audioUrl) => {
        setCurrentTab('listen');
        const audioElement = document.getElementById(`audio-${audioUrl}`);
        if (audioElement) {
            const [minutes, seconds] = savedTimes[audioUrl].split(':').map(Number);
            const timeInSeconds = minutes * 60 + seconds;
            audioElement.currentTime = timeInSeconds;
            audioElement.play();
        }
    };

    const handleLongClick = (audioUrl) => {
        setSelectedAudioUrl(audioUrl);
        setShowOptionsModal(true);
    };

    const handleDelete = () => {
        const newSavedTimes = { ...savedTimes };
        delete newSavedTimes[selectedAudioUrl];
        setSavedTimes(newSavedTimes);
        localStorage.setItem(`savedTimes_${book.title}`, JSON.stringify(newSavedTimes));
        setShowOptionsModal(false);
        setSelectedAudioUrl(null);
    };

    const handleSaveMessage = (message) => {
        const newSavedMessages = { ...savedMessages, [selectedAudioUrl]: message };
        setSavedMessages(newSavedMessages);
        localStorage.setItem(`savedMessages_${book.title}`, JSON.stringify(newSavedMessages));
        setShowOptionsModal(false);
        setSelectedAudioUrl(null);
    };

    const handlePlay = (audioElement) => {
        if (playingAudio && playingAudio !== audioElement) {
            playingAudio.pause();
            setPlayingAudio(null);
        }
        setPlayingAudio(audioElement);
    };

    const renderListenTab = () => {
        return (
            <div className="audio-list">
                {book.audioUrls.map((audioUrl, index) => (
                    <div key={index} className="audio-item">
                        <AudioControl
                            audioUrl={audioUrl}
                            onSaveTime={(currentTime) => saveCurrentTime(audioUrl, currentTime)}
                            savedTime={savedTimes[audioUrl]}
                            id={`audio-${audioUrl}`}
                            onPlay={handlePlay}
                            playingAudio={playingAudio}
                            audioFileName={book.audioFileNames[index]}
                        />
                    </div>
                ))}
            </div>
        );
    };

    const renderSavedTab = () => {
        return (
            <div className="saved-times">
                {Object.keys(savedTimes).map((audioUrl, index) => (
                    <div
                        className='saved-time'
                        key={index}
                        onClick={() => handleSavedTimeClick(audioUrl)}
                        onContextMenu={(e) => {
                            e.preventDefault();
                            handleLongClick(audioUrl);
                        }}
                    >
                        <p>{book.audioFileNames[index]} - {savedTimes[audioUrl]}</p>
                        {savedMessages[audioUrl] && <p>{savedMessages[audioUrl]}</p>}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="modal">
            <div className="modal-content" ref={modalContentRef} onScroll={handleScroll}>
                <header className='modal-header'>
                    <img onClick={closeModal} className='close' alt='back' src={backIcon} />
                    <h2>{book.title}</h2>
                </header>
                <div className="image-container">
                    {book.imageWallpaper ? (
                        <img src={book.imageWallpaper} alt={book.title} className="book-image" />
                    ) : (
                        <img src={book.imageUrl} alt={book.title} className="book-image" />
                    )}
                </div>
                <div className="tabs">
                    <button
                        className={currentTab === 'listen' ? 'active' : ''}
                        onClick={() => setCurrentTab('listen')}
                    >
                        Тыңдау
                    </button>
                    <button
                        className={currentTab === 'saved' ? 'active' : ''}
                        onClick={() => setCurrentTab('saved')}
                    >
                        Сақталған
                    </button>
                </div>
                <div>
                    <p className="card-description">Сипаттамасы: {book.description}</p>
                    {book.sound && (
                        <p className="card-sound">Дыбыстаушы: {book.sound}</p>
                    )}
                </div>
                {currentTab === 'listen' && renderListenTab()}
                {currentTab === 'saved' && renderSavedTab()}
                {savedMessage && <div className="saved-message">{savedMessage}</div>}
                {showOptionsModal && (
                    <ModalOptions
                        onClose={() => setShowOptionsModal(false)}
                        onDelete={handleDelete}
                        onSaveMessage={handleSaveMessage}
                        savedTime={savedTimes[selectedAudioUrl]}
                    />
                )}
            </div>
        </div>
    );
};

export default Read;