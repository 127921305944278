import React, { useEffect } from 'react';
import '../styles/modalSuccess.css';

const ModalSuccess = ({ message, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 3000);

        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div className="modal-success">
            <div className="modal-content-success">
                <p>{message}</p>
            </div>
        </div>
    );
};

export default ModalSuccess;