import React from 'react';
import { getDatabase, ref, set, push, query, orderByChild, equalTo, get } from 'firebase/database';
import { db, auth } from '../config';
import { doc, getDoc } from '@firebase/firestore';

const PayButton = ({ productId, productName, productPrice, showSuccessModal }) => {
  const handlePayment = async () => {
    const user = auth.currentUser;

    if (user) {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();

          const db = getDatabase();
          const ordersRef = ref(db, 'orders');
          const ordersQuery = query(ordersRef, orderByChild('currentUserId'), equalTo(user.uid));
          const ordersSnapshot = await get(ordersQuery);

          let hasOrderedSameBook = false;

          ordersSnapshot.forEach((childSnapshot) => {
            if (childSnapshot.val().bookId === productId) {
              hasOrderedSameBook = true;
            }
          });

          if (hasOrderedSameBook) {
            showSuccessModal('Сіздің өтінім қаралып жатыр');
          } else {
            const orderData = {
              bookId: productId,
              currentUserId: user.uid,
              number: userData.number || '',
              bookName: productName,
              displayName: user.displayName || '',
            };

            const newOrderRef = push(ordersRef);
            await set(newOrderRef, orderData);

            showSuccessModal('Аудио кітапты төлем жасағаннан кейін тыңдай аласыңыз');
          }
        } else {
          alert('User data not found!');
        }
      } catch (error) {
        console.error('Error processing payment:', error);
        alert('An error occurred while processing your payment. Please try again.');
      }
    } else {
      alert('User not authenticated!');
    }
  };

  return (
    <button className="pay-button" onClick={handlePayment}>
      KaspiGold {productPrice} tenge
    </button>
  );
};

export default PayButton;