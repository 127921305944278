import React from 'react';

const AuthorCard = ({ imageUrl, name }) => {
  return (
    <div className="author-card">
      <img src={imageUrl} alt={name} className="author-image" />
      <p className="author-name">{name}</p>
    </div>
  );
};

export default AuthorCard;